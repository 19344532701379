<template>
    <div class="billing-dashboard" v-if="loaded">
        <div class="page-header">
            <div>
                <client-account-header></client-account-header>
                <h2>Billing</h2>
            </div>
            <div class="header-controls">
                <router-link to="/payments/" class="button button-2">Payment History</router-link>
                <router-link to="/invoices/" class="button button-2">Past Invoices</router-link>
                <button @click="filterPastDue"><i class="fa-regular fa-bars-filter"></i>past due</button>
                <button @click="filterVeryPastDue"><i class="fa-regular fa-bars-filter"></i>40+ Days past due</button>
            </div>
        </div>
        <div class="columizer">
            <transition-group name="cross-fade">
                <div key="abcd" class="unpaid-invoices">
                    <simple-table 
                        :columns="columns" 
                        :model="InvoiceModel" 
                        :records="unpaidInvoiceIds" 
                        :filtersOff="true" 
                        :rowControlsWidth="0.5"
                        :initial-order="{prop: 'dueDate', direction: 1}"
                        @click:row="toggleInvoice($event)"
                        >
                        <template v-slot:rowcontrols=" { record } ">
                            <div class="form-group">
                                <label v-if="record" @click.prevent.stop="toggleInvoice(record.id)">
                                    <input type="checkbox" :checked="selectedInvoiceIds.indexOf(record.id+'') !== -1" /><span></span>
                                </label>
                            </div>
                        </template>
                        <template v-slot:rowcontrolsHeader>
                            <div class="form-group">
                                <label>
                                    <input type="checkbox" v-model="allSelected" /><span></span>
                                </label>
                            </div>
                        </template>
                    </simple-table>
                </div>

                <div class="window" key="bada">
                    <div class="window-header">
                        <h1>Make a Payment</h1>
                    </div>
                    <div class="window-wrapper">
                        <transition-group name="cross-fade">
                            <table class="headless-table" key="a1">
                                <tbody>
                                    <transition-group name="cross-fade">
                                        <tr v-for="allocation in Object.values(paymentDTO.paymentAllocations).sort((x, y) => invoices[x.invoiceId].dueDate - invoices[y.invoiceId].dueDate)" :key="allocation.invoiceId">
                                            <td>{{invoices[allocation.invoiceId].invoiceNumber}} (${{df(invoices[allocation.invoiceId].balanceDue)}})</td>
                                            <td>$<input type="text" :value="allocation.amount" @input="allocate(allocation.id, $event.target.value)" @blur="blurred(allocation.id)" /></td>
                                        </tr>
                                        <tr key="a1" v-if="paymentDTO.paymentAllocations.length > 1">
                                            <td>Invoices total ({{paymentDTO.paymentAllocations.length}})</td>
                                            <td>${{df(paymentMax)}}</td>
                                        </tr>
                                        <tr key="a2">
                                            <td>Invoice credits (0)</td>
                                            <td>$0</td>
                                        </tr>
                                        <tr key="a3" v-if="paymentDTO.paymentAllocations.length">
                                            <td>Payment Amount:</td>
                                            <td>$<input type="text" :value="paymentDTO.total" @input="reallocate" @blur="blurred(null)" /></td>
                                        </tr>
                                        <tr key="a4" v-else>
                                            <td colspan="2">Select invoices to apply payment</td>
                                        </tr>
                                    </transition-group>
                                </tbody>                                
                            </table>
                            <div v-if="!collapsibles.payment" class="form-controls" key="k2">
                                <button 
                                    @click="collapsibles.payment = true" 
                                    :disabled="paymentDTO.paymentAllocations.length === 0 || paymentDTO.total === 0 || paymentDTO.total === '0.00'"
                                >
                                    Pay this amount
                                </button>
                            </div>
                            <h4 key="a3a" v-if="collapsibles.payment">Payment information</h4>
                            <div key="a3" v-if="collapsibles.payment" class="form-group radio-line">
                                <label><input type="radio" v-model="paymentDTO.valueof.paymentType" :value="0" /><span>Credit card</span></label>
                                <label><input type="radio" v-model="paymentDTO.valueof.paymentType" :value="1" /><span>Bank account</span></label>
                            </div>
                            <fieldset key="a4" v-if="collapsibles.payment && paymentDTO.valueof.paymentType === 0">
                                <div class="form-group">
                                    <label>
                                        <span>Name on card</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Card number</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Expiry date</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Security code</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Billing zip/postal code</span>
                                        <input type="text" />
                                    </label>
                                </div>

                            </fieldset>
                            <fieldset key="a5" v-if="collapsibles.payment && paymentDTO.valueof.paymentType === 1">
                                <div class="form-group">
                                    <label>
                                        <span>Name on account</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Account type</span>
                                        <select>
                                            <option>Business checking</option>
                                            <option>Personal checking</option>
                                        </select>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Routing number</span>
                                        <input type="text" />
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <span>Account number</span>
                                        <input type="text" />
                                    </label>
                                </div>

                            </fieldset>
                        </transition-group>
                    </div>
                    <div v-if="collapsibles.payment" class="window-controls form-controls">
                        <button @click="submit">Submit payment</button>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import SimpleTable from '../components/tables/SimpleTable.vue';

import InvoiceModel from '../models/InvoiceModel';
import PaymentCreateDTO from '../models/PaymentCreateDTO';
import PaymentAllocationCreateDTO from '../models/PaymentAllocationCreateDTO';
import ClientAccountHeader from '../components/clientAccounts/ClientAccountHeader.vue';
import { reactive, ref } from '@vue/reactivity';
import {computed,} from '@vue/runtime-core';
import database from "@/store/database";

const columns = {
    'dueDate' : {
        title : "Due Date",
        sortable: true,
        width: 3,
    },
    'invoiceNumber' : {
        title : "Invoice #",
        sortable: true,
        width: 4,
    },
    'clientAccount': {
        title: "Account",
        sortable: true,
        format: '{clientAccount.name} - {clientAccount.accountNumber}',
        width: 6,
    },
    'equipment.trailerNumber' : {
        title : "Equipment",
        sortable: 'serviceRequest.equipmentIssues.equipment.trailerNumber',
        multiple: 'ifcount',
        width: 4,
    },
    'total' : {
        title: "Total",
        sortable: true,
        format: 'money',
        width: 3,
    },
    'balanceDue' : {
        title: "Balance",
        format: 'money',
        sortable: false,
        width: 3,
    },
}

export default {
    components: {
        SimpleTable,
        ClientAccountHeader

    },
    setup(){
        const promises = [];
        const loaded = ref(false);
        const collapsibles = reactive({});
        const df = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format;
        const invoiceTable = ref();
        
        const paymentDTO = reactive(new PaymentCreateDTO({
            paymentType: 0,
            total: 0,
        }));

        promises.push(database[InvoiceModel.name].equals('status', 1).loader);
        promises.push(database[InvoiceModel.name].orderBy('dueDate', 1).loader);
        
        //array of cross data between 
        const invoices = database[InvoiceModel.name];

        const unpaidInvoiceIds = database[InvoiceModel.name].equals('status', 1).toReactive();

        
        
        const selectedInvoiceIds = computed(() => {
            return Object.keys(paymentDTO.paymentAllocations);
        });





        const toNumber = (amount) => {
            if (typeof amount === 'string'){
                amount = parseFloat(amount.replace(/,/g, ''));
            }
            return amount;
        };
        const reallocate = (event) => {
            let amount = toNumber(event.target.value);
            paymentDTO.total = amount;

            //oldest-first pay scheme
            let sortedAllocations = database[InvoiceModel.name].orderBy('dueDate', 1, Object.values(paymentDTO.paymentAllocations)).toArray();
            for (var i in sortedAllocations){
                let balanceDue = invoices[sortedAllocations[i].invoiceId].balanceDue;

                if (balanceDue > amount){
                    paymentDTO.paymentAllocations[sortedAllocations[i].id].amount = df(amount);
                    amount = 0;

                } else {
                    paymentDTO.paymentAllocations[sortedAllocations[i].id].amount = df(balanceDue);
                    amount -= balanceDue;
                }
            }
        };

        const setTotal = () => {
            let total = 0;
            for (var allocation in paymentDTO.paymentAllocations){
                total += toNumber(paymentDTO.paymentAllocations[allocation].amount);
            }
            paymentDTO.total = df(total);
        };
        
        
        
        
        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return { 
            paymentDTO,
            collapsibles, 
            invoices, 
            unpaidInvoiceIds,
            selectedInvoiceIds,
            loaded,
            invoiceTable,
            
            reallocate,
            df,
            setTotal,
            toNumber,

            InvoiceModel,
            columns,
        };
    },
    data: function () {
        return {
            today: new Date(Math.floor(new Date().getTime() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000)),
            fourtyDaysAgo: new Date(Math.floor(new Date(new Date().setDate(new Date().getDate() - 40)).getTime() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000)),
            focused: false,
        }
    },
    methods: {
        allocate(id, value){
            let amount = this.toNumber(value);
            this.paymentDTO.paymentAllocations[id].amount = amount;
            this.setTotal();
        },
        

        blurred(id){
            if (id === null){
                console.log(this.toNumber(this.paymentDTO.total));
                let amount = this.toNumber(this.paymentDTO.total);
                amount = amount > this.paymentMax ? this.paymentMax : amount;
                this.paymentDTO.total = this.df(amount);

            } else {
                console.log(this.toNumber(this.paymentDTO.paymentAllocations[id].amount));
                let amount = this.toNumber(this.paymentDTO.paymentAllocations[id].amount);
                amount = amount > this.invoices[id].balanceDue ? this.invoices[id].balanceDue : amount;
                this.paymentDTO.paymentAllocations[id].amount = this.df(amount);
                this.setTotal();
            }
        },


        toggleInvoice(invoiceId, set = null){
            if (set === true || set !== false && !this.paymentDTO.paymentAllocations.has(invoiceId)){
                this.paymentDTO.paymentAllocations[invoiceId] = new PaymentAllocationCreateDTO({
                    id: invoiceId,
                    invoiceId: invoiceId,
                });
                this.paymentDTO.paymentAllocations[invoiceId].amount = this.df(this.invoices[invoiceId].balanceDue);

            } else if (set !== true) {
                delete this.paymentDTO.paymentAllocations[invoiceId];
            }
            this.setTotal();
            return false;
        },
        selectAll(){
            let set = true;
            if (this.allSelected){
                set = false;
            }
            this.unpaidInvoiceIds.forEach(x => this.toggleInvoice(x, set));
       
        },
        filterPastDue(){
            alert("Pretend filtered past due. It got lost in the mail.");
            // for(var i in this.unpaidInvoices){
            //     if(this.unpaidInvoices[i].dueDate < this.today){
            //         this.toggleInvoice(this.unpaidInvoices[i].id, true);
            //     } else {
            //         this.toggleInvoice(this.unpaidInvoices[i].id, false)
            //     }
            // }
        },
        filterVeryPastDue(){
            alert("Pretend filtered really past due. Would you believe it got lost in the mail... again?");
            // for(var i in this.unpaidInvoices){
            //     if(this.unpaidInvoices[i].dueDate < this.fourtyDaysAgo){
            //         this.toggleInvoice(this.unpaidInvoices[i].id, true);
            //     } else {
            //         this.toggleInvoice(this.unpaidInvoices[i].id, false)
            //     }
            // }
        },
        submit(){
            alert("!!Pretend the payment went through. This will automatically take you to the details page for the successful payment.");
        }
    },
    computed: {
        allSelected: {
            get() {
                return this.paymentDTO.paymentAllocations.length === this.unpaidInvoiceIds.length
            },
            set() {
                this.selectAll();
            },            
        },
        paymentMax(){
            if (Object.keys(this.paymentDTO.paymentAllocations).length){
                let total = 0;
                for (var allocation in this.paymentDTO.paymentAllocations){
                    total += this.invoices[allocation].balanceDue;
                }
                return total;
            }
            return 0;
        },
    },
}
</script>

<style lang="scss">
@import '../assets/scss/variables';

.billing-dashboard{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
    overflow: hidden;
    
    .unpaid-invoices{
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-right: $space-narrow;
        position: relative;
        background: $white;

        @include custom-scroll;

        >table{
            width: 100%;
        }
    }
    .window{
        width: 400px;
        flex: 0 0 auto;
        margin-left: $space-narrow;

        .window-wrapper{
            flex-direction: column;
        }
    }
    .simple-table-top{
        position: sticky;
        top:0;
        z-index: 70;
    }
    .simple-table{
        .form-group{
            padding-bottom: 0;
            >label{
                margin-bottom: 0;
                >span{
                    margin-bottom: 0;
                    &::before{
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .window-wrapper{
        justify-content: start;
        position: relative;
        margin: 0;

        fieldset{
            display: block;
        }
        h4{
            margin-top: $space-wide;
        }
    }
    .headless-table{
        width: 100%;
        td{
            +td{
                text-align: right;
                font-family: monospace;
            }
        }
        input{
            margin-top: $space-narrow;
            float:right;
            width: 100px;
            text-align: right;
            font-family: monospace;
            line-height: 32px;
            height: 34px;
        }
    }
}

.columizer{
    margin: 0 auto;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}
</style>
