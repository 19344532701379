import Model from './Model.js';


class PaymentAllocationCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', String, true);
        this.prop('created', Date, false);
        this.prop('invoiceId', Number, false);
        this.prop('paymentId', Number, true);
        this.prop('amount', Number, false);

        this.populate(values, config);

    }
    static name = 'PaymentAllocationCreateDTO';
}
window[Symbol.for(PaymentAllocationCreateDTO.name)] = PaymentAllocationCreateDTO;

export default PaymentAllocationCreateDTO;