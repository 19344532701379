<template>
    <transition name="client-account-header" appear>
        <h5 
            class="client-account-header" 
            @click="$router.push('/reporting')" 
            v-if="clientAccounts.loaded && Object.hasOwnProperty.call($store.state, 'UserProfile')"
        >
            {{clientAccounts[$store.state.UserProfile.clientAccount].name}}/
        </h5>
    </transition>
</template>

<script>
import ClientAccountModel from '../../models/ClientAccountModel';
import database from "@/store/database";
import store from "@/store/store";
export default {
    data: function(){
        return {
            loaded: false
        }
    },
    setup: () => {
        const promises = [];
        promises.push(database[ClientAccountModel.name].load([store.state.UserProfile.clientAccount]));
        
        const clientAccounts = database[ClientAccountModel.name];
        
        
        return {
            clientAccounts,
        }  
    },
}
</script>


<style lang="scss">
@import "../../assets/scss/variables.scss";
.client-account-header{
    display: block;
    cursor: pointer;
    color: $brand;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-secondary;
    font-weight: $font-bold;
    height: 16px;
    transform: translateY(0);
    opacity: 1;
    transition: 1s ease;

    + h2{
        position: relative;
        margin-top: 0px;
        line-height: 24px;
    }
    &.client-account-header-enter-active{
        + h2{
            transition: 1s ease;
        }
    }
    &.client-account-header-enter-from{
        transform: translateY(-1000%);
        opacity: 0;
        margin-top: -16px;

        + h2{
            margin-top:-21px;
        }
    }

}
</style>