import Model from './Model.js';

import PaymentType from './PaymentType.js';
import PaymentAllocationCreateDTO from './PaymentAllocationCreateDTO.js';

class PaymentCreateDTO extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', String, true);
        this.prop('created', Date, false);
        this.prop('paymentType', PaymentType, false);
        this.prop('amount', Number, false);
        this.prop('paymentAllocations', [PaymentAllocationCreateDTO], false);

        this.populate(values, config);

    }
    static name = 'PaymentCreateDTO';
}
window[Symbol.for(PaymentCreateDTO.name)] = PaymentCreateDTO;

export default PaymentCreateDTO;